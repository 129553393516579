import React from "react"
import "./Project.scss"

interface IPROPS {
  project: {
    title: string
    github: string
    deploy: string
    techstack: string[]
    previewUrl: string
    previewMimeType: string
    description: string
    highlights: string[]
  }
}

const Project = (props: IPROPS) => {
  const { project } = props

  return (
    <div className="project">
      <div className="project__heading">
        <h3 className="project__title">{project.title}</h3>

        <div className="project__links">
          <a href={project.github}>
            <span>GITHUB</span>
          </a>

          {project.deploy && (
            <a href={project.deploy}>
              <span>SITE</span>
            </a>
          )}
        </div>
      </div>

      <ul className="project__techstack">
        {project.techstack.map((technology, idx) => (
          <li key={idx}>{technology}</li>
        ))}
      </ul>

      {project.previewMimeType === "image/png" ? (
        <img
          src={project.previewUrl}
          className="project__preview"
          alt={`${project.title}'s preview image`}
        />
      ) : (
        <video className="project__preview" autoPlay muted loop playsInline>
          <source src={project.previewUrl} type="video/mp4" />
        </video>
      )}

      <p className="project__description">{project.description}</p>

      <ul className="project__highlights">
        {project.highlights.map((highlight, idx) => (
          <li key={idx}>{highlight}</li>
        ))}
      </ul>
    </div>
  )
}

export default Project
