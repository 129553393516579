import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Project from "./Project"
import "./Projects.scss"

const Projects: React.FC = props => {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              id
              title
              github
              deploy
              techstack
              description
              highlights
              previewUrl
              previewMimeType
            }
          }
        }
      }
    }
  `)

  return (
    <div id="projects" className="projects">
      <h2 className="projects__heading">PROJECTS</h2>

      {edges
        .map(({ node }) => (
          <div key={node.frontmatter.id}>
            <Project project={node.frontmatter} />
          </div>
        ))
        .sort((a, b) => a.key - b.key)}
    </div>
  )
}

export default Projects
