import React from "react"

// components
import Header from "../components/Header/Header"
import Layout from "../components/layout/index"
import About from "../components/About/About"
import Projects from "../components/Projects/Projects"
import SEO from "../components/seo"

const landing: React.FC = () => {
  return (
    <Layout>
      <SEO title={"Skyelar Carroll | Software Developer"} />
      <Header />
      <About />
      <Projects />
    </Layout>
  )
}

export default landing
