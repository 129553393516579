import React from "react"
import "./About.scss"

const About: React.FC = () => {
  return (
    <div id="about" className="about">
      <h2 className="about__heading">Hey</h2>
      <p className="about__me">
        I’m Skyelar! And I’m Software Developer from Southern California,
        specializing in Full-Stack Web Development. I have a passion for
        creating robust back-end applications utilizing a variety of
        technologies including <span>Node, Express, and Python.</span> Often
        accompanied by beautiful and easy-to-use front-end applications built in{" "}
        <span>React.</span>
        <br />
        <br />
        When I’m not developing software, I love to learn about space! Did you
        know If two pieces of the same type of metal touch in space, they will
        bond and be permanently stuck together? This is known as{" "}
        <span>Cold Welding. </span>
        Pretty neat right?
      </p>
    </div>
  )
}

export default About
