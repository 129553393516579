import React from "react"
import { Link } from "gatsby"
// styles
import "./Header.scss"

// assets
import sun from "../../assets/sun.svg"
import shuttle from "../../assets/shuttle.svg"
import planet_moon from "../../assets/planet_moon.svg"
import ringed_planet from "../../assets/ringed_planet.svg"
import smoke from "../../assets/cloud.svg"
import chevron from "../../assets/icons/chevron.svg"

const Header: React.FC = () => {
  return (
    <div className="header">
      <div className="header__container">
        <h1 className="header__heading">HI, I'M SKYELAR</h1>
        <h1 className="header__heading--mobile">SKYELAR</h1>
        <p className="header__subheading">
          and I'm a software developer from southern california
        </p>
        <p className="header__subheading--mobile">Software Developer</p>

        <nav className="header__nav">
          <a href="#about">
            <li>About</li>
          </a>
          <a href="#projects">
            <li>Projects</li>
          </a>
          <Link to="#projects">
            <li>Resume</li>
          </Link>

          <a
            href="https://github.com/Fractured2K"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li>Github</li>
          </a>

          <a
            href="https://www.linkedin.com/in/skyelar-carroll-7567b217a/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li>LinkedIn</li>
          </a>
        </nav>

        <img className="header__down" src={chevron} alt="Chevron Icon" />
      </div>

      <img className="header__sun" src={sun} alt="Illustration of the sun" />
      <img
        className="header__ringed_planet"
        src={ringed_planet}
        alt="Illustration of a Ringed Planet"
      />
      <img
        className="header__planet_moon"
        src={planet_moon}
        alt="Illustration of a Moon"
      />
      <img
        className="header__shuttle"
        src={shuttle}
        alt="Illustration of a Shuttle"
      />
      <img
        className="header__cloud"
        src={smoke}
        alt="Illustration of a cloud"
      />
    </div>
  )
}

export default Header
